















import { Component, Inject, Prop, Vue } from 'vue-property-decorator';

import { Api } from '@/api';
import { ExternalFileApi } from '@/api/external_file_api';
import CaseStudy from '@/api/models/case_study';
import CaseStudyInputTemplate from '@/components/CaseStudyInputTemplate.vue';
import { GlobalLoadingScreenModule } from '@/store/global_loading_screen';
import { GlobalMe } from '@/store/global_me';
import { GlobalMessageModule } from '@/store/global_message';
import { GlobalTag } from '@/store/global_tag';
import { makeForm } from '@/utils/form';

@Component({
  components: {
    CaseStudyInputTemplate
  }
})
export default class CaseStudyEdit extends Vue {
  @Prop({ required: true })
  caseStudyUuid!: string;

  @Inject()
  api!: Api;

  @Inject()
  externalFileApi!: ExternalFileApi;

  @Inject()
  globalLoadingScreenModule!: GlobalLoadingScreenModule;

  @Inject()
  globalMessageModule!: GlobalMessageModule;

  @Inject()
  globalTagModule!: GlobalTag;

  @Inject()
  globalMeModule!: GlobalMe;

  active = false;

  form = makeForm(CaseStudy);

  get tags() {
    return this.globalTagModule.tags;
  }

  async created() {
    try {
      const caseStudy = await this.globalLoadingScreenModule.track(this.api.getCaseStudy(this.caseStudyUuid));
      await this.editableCheck(caseStudy);
      this.form = makeForm(CaseStudy, caseStudy);
    } catch (error) {
      this.globalMessageModule.addMessages('error', error.modelMessages(), 'next');
      this.$router.push({ name: 'CaseStudiesIndex' });
    }
  }

  async editableCheck(caseStudy: CaseStudy) {
    const me = await this.globalMeModule.getMe();
    if (!me.canEditCaseStudy(caseStudy)) {
      this.globalMessageModule.addMessages('error', ['編集権限がありません'], 'next');
      this.$router.push({ name: 'CaseStudiesIndex' });
    }
  }

  async onSave() {
    if (this.active) return;
    this.active = true;
    try {
      await this.globalLoadingScreenModule.track(
        this.api.putCaseStudy(this.form)
      );
      this.form = makeForm(CaseStudy, this.form);
      this.globalMessageModule.addMessages('info', [`${this.form.title}の事例を更新しました。`], 'next');
      this.$router.push({ name: 'CaseStudyShow', params: { 'case_study_uuid': this.caseStudyUuid } });
    } finally {
      this.active = false;
    }
  }

  onCancel() {
    this.$router.push({ name: 'CaseStudyShow', params: { 'case_study_uuid': this.caseStudyUuid } });
  }

  async onDelete() {
    if (this.active) return;
    this.active = true;
    try {
      await this.globalLoadingScreenModule.track(
        this.api.deleteCaseStudy(this.caseStudyUuid)
      );
      this.globalMessageModule.addMessages('info', [`${this.form.title}の事例を削除しました。`], 'next');
      this.$router.push({ name: 'CaseStudiesIndex' });
    } finally {
      this.active = false;
    }
  }
}
